import { useEffect } from "react";
import { Routes, Route, redirect } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import Home from "./pages/Home";
import SoftServeMenu from "./pages/SoftServeMenu";
import TaiyakiMenu from "./pages/TaiyakiMenu";
import SomiSipsMenu from "./pages/SomiSipsMenu";
import Location from "./pages/Location";
import Shop from "./pages/Shop";
import Careers from "./pages/Careers";
import Story from "./pages/Story";
import FranchiseOne from "./pages/FranchiseOne";
import FranchiseTwo from "./pages/FranchiseTwo";
import Fundraising from "./pages/Fundraising";
import Faq from "./pages/Faq";
import CustomerService from "./pages/CustomerService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import ADA from "./pages/ADA";
import CCPA from "./pages/CCPA";
import AllergenInfo from "./pages/AllergenInfo";
import NutritionFacts from "./pages/NutritionFacts";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import FranchiseInquiryForm from "./pages/FranchiseInquiryForm";
import FranchiseApplicationForm from "./pages/FranchiseAppplicationForm";

import ConvoyMenu from "./pages/ConvoyMenu";

import LabSurvey from "./pages/LabSurvey";
import LabStore from "./pages/LabStore";
const App = () => {
  // Route <--> Content Assignment
  const Redirect = ({ link }) => {
    window.location.replace(link);
  };
  const routes_pages = {
    //PAGES
    "/": <Home />,
    "/softserve": <SoftServeMenu />,
    "/taiyaki": <TaiyakiMenu />,
    "/somisips": <SomiSipsMenu />,
    "/locations": <Location />,
    "/shop": <Shop />,
    "/careers": <Careers />,
    "/story": <Story />,
    "/franchise-one": <FranchiseOne />,
    "/franchise-two": <FranchiseTwo />,
    "/fundmi": <Fundraising />,
    "/faq": <Faq />,
    "/customer-service": <CustomerService />,
    "/privacy-policy": <PrivacyPolicy />,
    "/terms-of-use": <TermsOfUse />,
    "/ada": <ADA />,
    "/ccpa-notice": <CCPA />,
    "/nutrition-facts": <NutritionFacts />,
    "/allergen-info": <AllergenInfo />,
    "/swirl-of-the-week": <Redirect link={"/softserve"} />,
    "/labs": <LabSurvey />,
    "/lab-store": <LabStore />,
    "/mobile-menu/convoy": <ConvoyMenu />,
    //FORMS
    "/store-application": <Redirect link={"https://forms.monday.com/forms/e0d19505b0ffeebff952a708a64d4280?r=use1"} />,
    "/franchise-application": <FranchiseInquiryForm />,
    // "/franchise-application": <FranchiseApplicationForm />,
    //"/fundmi-form": ""
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to homepage if invalid url
    if (!Object.keys(routes_pages).includes(pathname)) {
      console.log("pathname", pathname);
      navigate("/");
    }
    // Scroll to the top when a new page loads
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Navbar />
      <Routes>
        {/* RENDER ALL PAGES */}
        {Object.entries(routes_pages).map(([route, content]) => (
          <Route path={route} element={content} />
        ))}
      </Routes>
      <Footer />
    </>
  );
};

export default App;
